import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Purchases() {

    return (
        <div  >
            <Card>
                <CardContent >
                    <br />
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="P5UPBUZC56TKQ" />
                            <table>
                                <tr>
                                    <td className="Purchase-header"><input type="hidden" name="on0" value="GDS Google Map WinForms" />
                                        GDS Google Map WinForms Core/ GDS Google Map WinForms
                                    </td>
                                    <td className="Purchase-selection">
                                        <select name="os0">
                                            <option value="Map WinForms Regular Signin 1 Seat">
                                                Permanent Regular Sign-in 1 Developer Seat $300.00 USD
                                            </option>
                                            <option value="Map WinForms Regular Signin 3 Seats">
                                                Permanent Regular Sign-in 3 Developer Seats $600.00 USD
                                            </option>
                                            <option value="Map WinForms Regular Signin 5 Seats">
                                                Permanent Regular Sign-in 5 Developer Seats $900.00 USD
                                            </option>
                                            <option value="Map WinForms Design Time Signin 1 Seat">
                                                Permanent Design Time Sign-in 1 Developer Seat $500.00 USD
                                            </option>
                                            <option value="Map WinForms Design Time Signin 3 Seats">
                                                Permanent Design Time Sign-in 3 Developer Seats $1,000.00 USD
                                            </option>
                                            <option value="Map WinForms Design Time Signin 5 Seats">
                                                Permanent Design Time Sign-in 5 Developer Seats $1,500.00 USD
                                            </option>
                                            <option value="Map WinForms No Signin Unlimited Seats">
                                                Permanent No Sign-in Unlimited Developer Seats $2,000.00 USD
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="hidden" name="currency_code" value="USD" />
                                    </td>
                                    <td>
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <br />
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="JGVTJ4TANKSP4" />
                            <table>
                                <tr>
                                    <td className="Purchase-header"><input type="hidden" name="on0" value="GDS Google Map WinForms" />
                                        GDS Google Map WinForms Core/ GDS Google Map WinForms
                                    </td>
                                    <td className="Purchase-selection">
                                        <select name="os0">
                                            <option value="Map WinForms Regular Signin 1 Seat">
                                                Annual Regular Sign-in 1 Developer Seat $500.00 USD
                                            </option>
                                            <option value="Map WinForms Regular Signin 3 Seats">
                                                Annual Regular Sign-in 3 Developer Seats $1,000.00 USD
                                            </option>
                                            <option value="Map WinForms Regular Signin 5 Seats">
                                                Annual Regular Sign-in 5 Developer Seats $1,500.00 USD
                                            </option>
                                            <option value="Map WinForms Design Time Signin 1 Seat">
                                                Annual Design Time Sign-in 1 Developer Seat $800.00 USD
                                            </option>
                                            <option value="Map WinForms Design Time Signin 3 Seats">
                                                Annual Design Time Sign-in 3 Developer Seats $1,600.00 USD
                                            </option>
                                            <option value="Map WinForms Design Time Signin 5 Seats">
                                                Annual Design Time Sign-in 5 Developer Seats $2,400.00 USD
                                            </option>
                                            <option value="Map WinForms No Signin Unlimited Seats">
                                                Annual No Sign-in Unlimited Developer Seats $3,200.00 USD
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="hidden" name="currency_code" value="USD" />
                                    </td>
                                    <td>
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <br />
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="9B23R9DRX3C8W" />
                            <table>
                                <tr>
                                    <td className="Purchase-header"><input type="hidden" name="on0" value="GDS Google Map WPF" />
                                        GDS Google Map WPF Core/ GDS Google Map WPF
                                    </td>
                                    <td className="Purchase-selection">
                                        <select name="os0">
                                            <option value="Map WPF Regular Signin 1 Seat">
                                                Permanent Regular Sign-in 1 Developer Seat $300.00 USD
                                            </option>
                                            <option value="Map WPF Regular Signin 3 Seats">
                                                Permanent Regular Sign-in 3 Developer Seats $600.00 USD
                                            </option>
                                            <option value="Map WPF Regular Signin 5 Seats">
                                                Permanent Regular Sign-in 5 Developer Seats $900.00 USD
                                            </option>
                                            <option value="Map WPF Design Time Signin 1 Seat">
                                                Permanent Design Time Sign-in 1 Developer Seats $500.00 USD
                                            </option>
                                            <option value="Map WPF Design Time Signin 3 Seats">
                                                Permanent Design Time Sign-in 3 Developer Seats $1,000.00 USD
                                            </option>
                                            <option value="Map WPF Design Time Signin 5 Seats">
                                                Permanent Design Time Sign-in 5 Developer Seats $1,500.00 USD
                                            </option>
                                            <option value="Map WPF No Signin Unlimited Seats">
                                                Permanent No Sign-in Unlimited Developer Seats $2,000.00 USD
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="hidden" name="currency_code" value="USD" />
                                    </td>
                                    <td>
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <br />
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="JC2JT2UR9TV6Y" />
                            <table>
                                <tr>
                                    <td className="Purchase-header"><input type="hidden" name="on0" value="GDS Google Map WPF" />
                                        GDS Google Map WPF Core/ GDS Google Map WPF
                                    </td>
                                    <td className="Purchase-selection">
                                        <select name="os0">
                                            <option value="Map WPF Regular Signin 1 Seat">
                                                Annual Regular Sign-in 1 Developer Seat $500.00 USD
                                            </option>
                                            <option value="Map WPF Regular Signin 3 Seats">
                                                Annual Regular Sign-in 3 Developer Seats $1,000.00 USD
                                            </option>
                                            <option value="Map WPF Regular Signin 5 Seats">
                                                Annual Regular Sign-in 5 Developer Seats $1,500.00 USD
                                            </option>
                                            <option value="Map WPF Design Time Signin 1 Seat">
                                                Annual Design Time Sign-in 1 Developer Seats $800.00 USD
                                            </option>
                                            <option value="Map WPF Design Time Signin 3 Seats">
                                                Annual Design Time Sign-in 3 Developer Seats $1,600.00 USD
                                            </option>
                                            <option value="Map WPF Design Time Signin 5 Seats">
                                                Annual Design Time Sign-in 5 Developer Seats $2,400.00 USD
                                            </option>
                                            <option value="Map WPF No Signin Unlimited Seats">
                                                Annual No Sign-in Unlimited Developer Seats $3,200.00 USD
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="hidden" name="currency_code" value="USD" />
                                    </td>
                                    <td>
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <br />
                    <p className='Purchase-paypal-note'>PayPal takes credit card, thus you don't need to have a PayPal account to buy!</p>
                    <p className="Purchase-body">You will receive your license key through email by the end of next business day after your purchase.</p>
                </CardContent>
            </Card>
        </div>
    );
}

export default Purchases;
