import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import unLockImg from './images/Unlock32.png';

function Licensing() {

    return (
        <div  >
            <Card>
                <CardContent >
                    <p className="Licensing-icon"><img src={unLockImg} alt={unLockImg} /></p>
                    <p className="Section-body">
                        Design time means that GDS assemblies are used inside Visual Studio when developers are using them to do development;
                        Runtime means that GDS assemblies are called by release-built assemblies outside Visual Studio.
                    </p>
                    <br />
                    <p className="Section-body">
                        For commercial usage, we charge design time developer license (seat) for each device on which GDS assemblies are running.
                        There are two types of licenses: permanent license or annual subscription license.
                    </p>
                    <br />
                    <p className="Section-body">
                        Permanent license will work for for a particular version of one edition of GDS assembly at the time of purchase. For
                        example, if you purchase GDS Google Map WinForms V13, your license key will work for both GDS Google Map WinForms Core 
                        V13 and GDS Google Map WinForms V13. But your license key will not work for GDS Google Map WinForms Core V14, nor GDS
                        Google Map WPF V13.
                    </p>
                    <br />
                    <p className="Section-body">
                        Annual subscription license will work for all versions of the same edition of GDS assembly that were released before
                        the expiry date of the license key, during subscription period or after subscription expires.
                        However, annual subscription license will not work for any version of the same edition of GDS assembly that was released
                        after the expiry date of the license key.
                    </p>
                    <br />
                    <p className="Section-body">
                        There are three sign-in options: regular sign-in, design time sign-in and no sign-in.
                    </p>
                    <br />
                    <p className="Section-body">
                        Regular sign-in means that GDS assemblies will sign in to GDS servers for the purpose of license verification in both
                        design time and runtime.
                    </p>
                    <br />
                    <p className="Section-body">
                        Design time sign-in means that GDS assemblies will sign in to GDS servers for the purpose of license verification in
                        design time, but will not sign in runtime.
                    </p>
                    <br />
                    <p className="Section-body">
                        No sign-in means that GDS assemblies will not sign in to GDS servers to verify the license key. In another word, such
                        license has unlimited developer seats.
                    </p>
                    <br />
                    <p className="Section-body">
                        In design time, the license key can be used on any device. However, at the same time, the total number of devices that GDS assembly is running on can not be more
                        than the total developer seats that have been purchased.
                    </p>
                    <br />
                    <p className="Section-body">
                        In runtime, it is royalty free. The valid license key can be used on any device with no restriction.
                    </p>
                    <br />
                    <p className="Licensing-icon"><img src={unLockImg} alt={unLockImg} /></p>
                    <p className="Section-body">
                        We offer a 30-day Trial Period for evaluating GDS assembly. The Trial Edition of GDS assembly contains limited functionalities and watermark will
                        be displayed.
                    </p>
                    <br />
                </CardContent>
            </Card>
        </div>
    );
}

export default Licensing;
