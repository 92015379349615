import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function MapPageFeatures() {

    return (
        <div>
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header">Proxy or Not</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Connect to internet directly or through a proxy</td>
                        </tr>

                        <tr>
                            <th className="Features-new-table-header">Different Map Implementations (V13 and up)</th>
                        </tr>
                        <tr>
                            <td className="Features-new-table-body">Raster map or vector map</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Map Types</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Road map, satellite, hybrid and terrain</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Google Controls</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Zoom control, map type control, overview control, pan control, street view control, rotate control, full screen control and scale control</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Map Events and Enables</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Events: initialized, idle, map type id changed, center changed, zoom changed, bounds changed, mouse move, mouse up, mouse down, mouse click and mouse double click</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Enables: 45 degree imagery, double click zoom, draggable, keyboard shortcut and scroll wheel</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Feature Layers</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add, retrieve and remove marker layers, <span className="Features-new-table-body">advanced marker layers (V13 and up)</span>, icon layers, polyline layers, polygon layers, circle layers, rectangle layers and heatmap layers</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Id, display name, collection and tag for each layer</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Inspectable, clickable, double clickable, draggable and z index for marker layers and icon layers</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Inspectable, clickable, double clickable, draggable, editable and z index for polyline layers, polygon layers, circle layers and rectangle layers</td>
                        </tr>
                        <tr>
                            <td className="Features-new-table-body">Clickable, draggable and z index for advanced marker layers (V13 and up)</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Visible, display settings and highlight display settings for each layer</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Show description for marker layers and icon layers</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Markers</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Markers: bubble pin, foam pin and ball pin providing three different shapes, multiple characters either in the center or out side of the marker, four different sizes and transparent marker colors</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to marker layer, remove from marker layer, change individual marker label and description, highlight or un-highlight individual marker</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual marker display settings and location</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Marker events: mouse over, mouse out, mouse click, mouse double click, drag start, drag and drag end</td>
                        </tr>

                        <tr>
                            <th className="Features-new-table-header">Advanced Markers (V13 and up)</th>
                        </tr>
                        <tr>
                            <td className="Features-new-table-body">Advanced markers: advanced pin, glyph pin and svg pin handling three different collision behaviors</td>
                        </tr>
                        <tr>
                            <td className="Features-new-table-body">Add to marker layer, remove from marker layer, change individual marker description, highlight or un-highlight individual marker</td>
                        </tr>
                        <tr>
                            <td className="Features-new-table-body">Change individual marker display settings, location and altitude</td>
                        </tr>
                        <tr>
                            <td className="Features-new-table-body">Marker events: mouse click, drag start, drag and drag end</td>
                        </tr>


                        <tr>
                            <th className="Features-table-header">Icons</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to icon layer, remove from icon layer, change individual icon label and description, highlight or un-highlight individual icon</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual icon display settings and location</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Icon events: mouse over, mouse out, mouse click, mouse double click, drag start, drag and drag end</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Polylines</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to polyline layer, remove from polyline layer, highlight or un-highlight individual polyline</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual polyline display settings and path</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Edit polyline by users</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Polyline events: mouse over, mouse out, mouse click, mouse double click, drag start, drag and drag end</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Polygons</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to polygon layer, remove from polygon layer, highlight or un-highlight individual polygon</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual polygon display settings and path</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Multiple rings in a polygon (holes in a polygon)</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Edit polygon by users</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Polygon events: mouse over, mouse out, mouse click, mouse double click, drag start, drag and drag end</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Circles</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to circle layer, remove from circle layer, highlight or un-highlight individual circle</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual circle display settings, center and radius</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Edit circle by users</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Circle events: mouse over, mouse out, mouse click, mouse double click, drag start, drag and drag end</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Rectangles</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to rectangle layer, remove from rectangle layer, highlight or un-highlight individual rectangle</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual rectangle display settings and boundary</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Edit rectangle by users</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Rectangle events: mouse over, mouse out, mouse click, mouse double click, drag start, drag and drag end</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Heatmaps</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add to heatmap layer, remove from heatmap layer</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Change individual heatmap display settings and data</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">KML Layers (KML and GeoRSS)</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add, retrieve and remove KML layers</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Get status and metadata</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Get and set options and url</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Events: click, default viewport changed and status changed</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Data Layers</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Load Geo Json data from web, from file and from string and display such data on the map</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Add, get and remove data feature</td> 
                        </tr>
                        <tr>
                            <td className="Features-table-body">Data feature supports point, multi point, line string, multi line string, polygon, multi polygon and geometry collection</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Override, set, revert and get style</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Events: add, remove, mouse click, mouse double click, mouse down, mouse out, mouse over and mouse up</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Map Image</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Retrieve current map as bitmap for further processing, saving, printing, etc.</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Geocoding Service</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Geocoder provides a service for converting between an address, a place id and a geographic coordinate.</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Geocoder can submit three requests: geocoder address request, geocoder location request and geocoder place id request.</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Events: geocoding completed</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Directions Service</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Direction requests and responses using a variety of methods of transportation</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Autodisplaying or customized handling direction results</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Event: directions service completed</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Enable: autodisplay found routes</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Save autodisplayed routes onto the map, retrieve them and remove them from the map</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Street View</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Display street view, switch between street view and map</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Elevation Service</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Location elevation requests and responses for elevations of separate, discrete locations</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Sampled path elevation requests and responses for elevations on a series of connected points along a path</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Event: elevation service completed</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Geometry Service</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Compute the distance between two locations</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Compute the length of a given path of several locations</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Compute the area of a polygonal area</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Compute the heading between two locations</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Compute the destination coordinates based on a particular heading, an origin location and the distance to travel</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Compute the destination between two locations based on a value between 0 and 1</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Determine whether a given point falls within a polygon</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Determine whether a given point falls on or near a polyline or on or near the edge of a polygon</td>
                        </tr>

                        <tr>
                            <th className="Features-table-header">Places Service</th>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Find place from phone number retrieves a list of places based on a phone number. </td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Find place from query retrieves a list of places based on a query string.</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Nearby search retrieves a list of places near a particular location, based on keyword or type.</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Text search retrieves a list of places based on a query string</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Get details retrieve details about the place identified by the given placeId.</td>
                        </tr>
                        <tr>
                            <td className="Features-table-body">Events: place search service completed, get place details completed </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
            <br />
        </div>
    );
}

export default MapPageFeatures;
