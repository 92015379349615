import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import image02 from './images/HP01.jpg';

function MapPageSummary() {

    return (
        <div>
            <Card>
                <CardContent >
                    <p className="Tab-Section-body" >
                        GDS Google Map is a .Net User Control, which integrates an interactive Google Map into your desktop applications,
                        providing a fast and convenient way for software developers to display customized geographic data on top of Google Map
                        and perform Google Map based operations and computations.
                    </p>
                    <br />
                    <p className="Tab-Section-body" >
                        GDS Google Map combines the power of .Net Desktop Control and Google Map API to provide an enhanced mapping experience. Currently,
                        there are four editions available:
                    </p>
                    <br />
                    <table>
                        <tr >
                            <td>
                                <p className="Tab-Section-body-purple" >GDS Google Map WinForms Core (.Net 6.0 and up)</p>
                            </td>
                            <td width="5%" />
                            <td>
                                <p className="Tab-Section-body-purple" >GDS Google Map WPF Core (.Net 6.0 and up)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className="Tab-Section-body-purple" >GDS Google Map WinForms (.Net 4.7.* and .Net 4.8.*)</p>
                            </td>
                            <td width="5%" />
                            <td>
                                <p className="Tab-Section-body-purple" >GDS Google Map WPF (.Net 4.7.* and .Net 4.8.*)</p>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <img src={image02} className="Home-Image" alt="GdsGoogleMap" />
                </CardContent>
            </Card>
            <br />
        </div>
    );
}

export default MapPageSummary;
