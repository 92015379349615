import { useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import DownloadFunctions from './DownloadFunctions';

function RegisterTrialLicenseDialog(props) {

    const ProductEditionGdsGoogleMapWinForms = 1;
    const ProductEditionGdsGoogleMapWpf = 2;
    const ProductNameGdsGoogleMapWinForms = 'GDS Google Map WinForms';
    const ProductNameGdsGoogleMapWpf = 'GDS Google Map WPF';

    const [sEmail, setEmail] = useState('');
    const [sConfirmedEmail, setConfirmedEmail] = useState('');
    const [sProductEdition, setProductEdition] = useState(ProductEditionGdsGoogleMapWinForms);
    const [sDisableRegisterButton, setDisableRegisterButton] = useState(false);
    const [sRegisterError, setRegisterError] = useState('');

    const [sRegistrationKey, setRegistrationKey] = useState('');
    const [sGetTrialLicenseKey, setGetTrialLicenseKey] = useState(false);
    const [sDisableGetKeyButton, setDisableGetKeyButton] = useState(false);
    const [sGetTrialLicenseKeyError, setGetTrialLicenseKeyError] = useState('');

    function onEmailChange(e) {
        setEmail(e.target.value);
    }

    function onConfirmedEmailChange(e) {
        setConfirmedEmail(e.target.value);
    }

    function onProductEditionChange(e) {
        setProductEdition(e.target.value);
    }

    function onRegisterButtonClick() {
        if (sEmail.length < 5) {
            setRegisterError("Please provide valid email.");
            return;
        }

        if (sConfirmedEmail.length < 5) {
            setRegisterError("Please provide valid confirmed email.");
            return;
        }

        if (sEmail !== sConfirmedEmail) {
            setRegisterError("Email does not match confirmed email.");
            return;
        }

        let productName = sProductEdition === ProductEditionGdsGoogleMapWinForms ? ProductNameGdsGoogleMapWinForms : ProductNameGdsGoogleMapWpf;

        setRegisterError("");
        setDisableRegisterButton(true);
        DownloadFunctions.registerTrialLicense(sProductEdition, productName, sEmail);
    }

    function closeDialogAndDisplayStatus(status) {
        setGetTrialLicenseKey(false);
        setEmail("");
        setConfirmedEmail("");
        setDisableRegisterButton(false);
        setRegisterError("");
        setRegistrationKey("");
        setGetTrialLicenseKeyError("");
        setDisableGetKeyButton(false);
        props.closeDialogAndDisplayStatus(status);
    }

    function onRegistrationKeyChange(e) {
        setRegistrationKey(e.target.value);
    }

    function showGetTrialLicenseKeyUi() {
        setRegistrationKey("");
        setGetTrialLicenseKey(true);
    }

    function onGetKeyButtonClick() {
        if (sRegistrationKey.length < 10) {
            setGetTrialLicenseKeyError("Please provide valid registration confirmation code.");
            return;
        }

        setGetTrialLicenseKeyError("");
        setDisableGetKeyButton(true);
        DownloadFunctions.completeTrialLicenseRegistration(sRegistrationKey);
    }

    DownloadFunctions.handleTrialLicenseRegistionError = closeDialogAndDisplayStatus;
    DownloadFunctions.proceedGetTrialLicenseKey = showGetTrialLicenseKeyUi;
    DownloadFunctions.completeGetTrialLicenseKey = closeDialogAndDisplayStatus;

    function renderTrialLicenseRegistration() {
         return (
             <table width={"100%"} cellpadding={"10"}>
                 <tr>
                     <td colSpan="3">
                         <TextField multiline fullWidth id="emailTextField" value={sEmail} label="Email" onChange={onEmailChange} />
                     </td>
                 </tr>
                 <tr>
                     <td colSpan="3">
                         <TextField multiline fullWidth id="confirmedEmailTextField" value={sConfirmedEmail} label="Confirmed Email" onChange={onConfirmedEmailChange} />
                     </td>
                 </tr>
                 <tr>
                     <td width={"50%"}>
                         <FormControl fullWidth>
                             <InputLabel id="productEditionInputLabel">Product</InputLabel>
                             <Select id="productEditionSelect" value={sProductEdition} label="Product" onChange={onProductEditionChange}>
                                 <MenuItem value={ProductEditionGdsGoogleMapWinForms}>{ProductNameGdsGoogleMapWinForms}</MenuItem>
                                 <MenuItem value={ProductEditionGdsGoogleMapWpf}>{ProductNameGdsGoogleMapWpf}</MenuItem>
                             </Select>
                         </FormControl>
                     </td>
                     <td width={"25%"} />
                     <td width={"25%"}>
                         <Button variant="contained" disabled={sDisableRegisterButton} onClick={onRegisterButtonClick} >Register</Button>
                     </td>
                 </tr>
                 <tr>
                     <td width={"100%"} align={"left"} colSpan={"2"}>
                         <p style={{ color: "red" }}>{sRegisterError}</p>
                     </td>
                 </tr>
             </table>
         );
    }

    function renderGetTrialLicenseKey() {
        return (
                <table width={"100%"} cellpadding={"10"}>
                <tr>
                    <th className="Download-table-header">
                            Please stay on this page, check your email, and then enter your registration confirmation code to complete trial license registration.
                        </th>
                    </tr>
                    <tr>
                        <td >
                            <TextField multiline fullWidth id="registrationKeyField" label="Registration Confirmation Code" value={sRegistrationKey} onChange={onRegistrationKeyChange} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Button variant="contained" disabled={sDisableGetKeyButton} onClick={onGetKeyButtonClick} >Complete Registration and Get Trial License Key</Button>
                        </td>
                    </tr>
                    <tr>
                        <td width={"100%"} align={"left"}>
                            <p style={{ color: "red" }}>{sGetTrialLicenseKeyError}</p>
                        </td>
                    </tr>
                </table>
        );
    }

    return (
        <Dialog onClose={props.closeDialog} open={props.open} fullWidth maxWidth="sm">
            <DialogTitle>Register for Trial License</DialogTitle>
            {sGetTrialLicenseKey ? renderGetTrialLicenseKey() : renderTrialLicenseRegistration()}
        </Dialog>
    );
}

export default RegisterTrialLicenseDialog;
